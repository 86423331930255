<template>
  <div class="CustomerCase">
    <div class="CustomerCaseheader">
      <span class="title1">客户案例</span><span class="arrows">></span>
      <span
        :class="[
          { title2hover: headerid == '1' ? 'title2hover' : '' },
          'title2',
        ]"
        @click="getlist('1')"
        >互联网</span
      >
      <span
        :class="[
          { title2hover: headerid == '2' ? 'title2hover' : '' },
          'title2',
        ]"
        @click="getlist('2')"
        >金融</span
      >
      <span
        :class="[
          { title2hover: headerid == '3' ? 'title2hover' : '' },
          'title2',
        ]"
        @click="getlist('3')"
        >制造</span
      >
    </div>
    <div class="title">
      {{ headerid == 1 ? "互联网" : headerid == 2 ? "金融" : "制造" }}
    </div>

    <div class="list">
      <div class="card" v-for="item in list" :key="item.id">
        <img src="../../../assets/welfare/2.png" />
        <div class="cardtitle">永辉超市</div>
        <div class="info">
          永辉超市为国内500强企业，Top零售商，首批将生鲜农产品引进现代超市的流通企业之一
        </div>
        <div class="bebottom"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerid: "1",
      list: [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
    };
  },
  mounted() {},
  methods: {
    getlist(typeid) {
      this.headerid = typeid;
    },
  },
};
</script>

<style scoped lang="less">
.CustomerCase {
  width: 80%;
  margin: 0 auto;

  .CustomerCaseheader {
    padding: 59px 0;
    font-size: 28px;
    border-bottom: 1px solid #979797;

    .title1 {
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
    }

    .arrows {
      color: #bebebe;
      padding-left: 25px;
    }

    .title2 {
      font-weight: 500;
      margin-left: 97px;
      color: #b4b4b4;
      cursor: pointer;
    }

    .title2hover {
      color: #2f7ffc;
      padding-bottom: 15px;
      border-bottom: 6px solid #2f7ffc;
    }
  }

  .title {
    margin-top: 84px;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
  }

  .list {
    margin-top: 50px;
    display: flex;
    // justify-content: center;
    flex-wrap: wrap;
    .card:hover {
      .bebottom {
        border-radius: 0 0 20px 20px;
        width: 338px;
        height: 17px;
        position: absolute;
        margin-top: 34px;
        margin-left: 18px;
        background-color: #2f7ffc;
      }
    }

    .card {
      cursor: pointer;
      width: 360px;
      height: 224px;
      box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
      border-radius: 10px;
      border: 1px solid #e8e8e8;
      padding: 40px 35px;
      margin-bottom: 63px;
      margin-left: 4%;
      .cardtitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        margin-top: 37px;
      }

      .info {
        margin-top: 9px;
        font-size: 20px;
        font-weight: 400;
        color: #9e9e9e;
        line-height: 32px;
      }

      img {
        width: 164px;
        height: 49px;
      }
    }
  }
//   .list:after {
//     content: "";
//     flex: auto;
//   }
}
</style>
